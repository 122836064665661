import Swiper from "swiper";
import 'swiper/css/bundle';
import '@/../css/pages/swiper.css';
import {expandFullScreen} from "@/utility/helper"
import {
  optionSyncThumbFullscreen,
  optionSyncThumbMain,
  optionThumbFullscreen,
  optionThumbMain
} from "@/modules/swiper/swiperOptions";
import {loadTiktok} from "@/modules/loadScript/loadScript";

const swipers = {
  thumb: {},
  main: {},
  fullScreen: {},
  thumbFullscreen: {}
};

export const listingSlideGallery = (
  tabSlide = 'gridPhoto',
  isUpdateSlide = true
) => ({
  modalOpen: false,
  expandFullscreen: false,
  tabSlide: tabSlide,
  init() {
    this.initMainSwiper('photo')
    this.initFullSwiper('photo')
    this.initFullSwiper('floor')
    this.initFullSwiper('video')
    this.initFullSwiper('tour')
  },
  setData(event: CustomEvent) {
    Object.keys(event.detail).forEach((key) => {
      if (key in this) {
        (this as any)[key] = event.detail[key];
      }
    });
  },
  initMainSwiper(name: string) {
    if (document.getElementById(`swiper-${name}-thumb`)) {
      /* Swiper thumb of main */
      swipers.thumb[name] = new Swiper(
        `#swiper-${name}-thumb` as any,
        optionThumbMain()
      );
    }

    if (document.getElementById(`swiper-${name}`)) {
      /* Swiper main */
      swipers.main[name] = new Swiper(
        `#swiper-${name}` as any,
        optionSyncThumbMain(swipers.thumb[name])
      );
    }
  },
  initFullSwiper(name) {
    if (document.getElementById(`swiper-${name}-thumb-fullscreen`)) {
      /* Swiper thumb of fullscreen */
      swipers.thumbFullscreen[name] = new Swiper(
        `#swiper-${name}-thumb-fullscreen` as any,
        optionThumbFullscreen()
      );
    }

    if (document.getElementById(`swiper-${name}-fullscreen`)) {
      /* Swiper fullscreen: video no loop */
      let options = {
        loop: name !== 'video',
      }
      swipers.fullScreen[name] = new Swiper(
        `#swiper-${name}-fullscreen` as any,
        optionSyncThumbFullscreen(name, swipers.thumbFullscreen[name], options)
      );
    }

    // Sync swiper slide main and fullscreen
    /*if (swipers.main[name] && swipers.fullScreen[name]) {
        swipers.main[name].controller.control = [swipers.fullScreen[name]];
        swipers.fullScreen[name].controller.control = swipers.main[name];
    }*/

    // Remove hide on page load
    document.getElementById(`swiper-${name}`)?.classList.remove('!hidden')
    document.getElementById(`swiper-${name}-thumb`)?.classList.remove('!hidden')
    document.getElementById(`swiper-${name}-thumb-fullscreen`)?.classList.remove('!hidden')
    document.getElementById(`swiper-${name}-fullscreen`)?.classList.remove('!hidden')
    // Update state swiper by keypress
    eventListenerUpdateSwiper(name, isUpdateSlide);
  },
  closeFullScreenPhoto() {
    this.tabSlide = 'gridPhoto';
    document.body.style.overflow = 'auto';
    // remove state for mobile back button
    replaceState(this.tabSlide);
  },
  activeSlideByIndex(
    tabName: string,
    index: number,
    isOverflowHidden: boolean = true
  ) {
    this.tabSlide = tabName;
    this.modalOpen = true;
    if (isOverflowHidden) {
      this.overflowBodyHidden();
    }
    // push state for mobile back button
    if (['photo', 'gridPhoto'].includes(tabName)) {
      pushState(tabName);
    }
    setTimeout(() => {
      swipers.fullScreen[tabName]?.slideToLoop(index);
    }, 300);
  },
  activeSlideByTab(tabName: string) {
    this.tabSlide = tabName;
    if (isUpdateSlide) {
      updateSlideByTab(tabName);
    }
  },
  activeTab(tabName: string) {
    this.tabSlide = tabName;
    this.modalOpen = true;
    this.overflowBodyHidden();
    pushState(tabName);
  },
  overflowBodyHidden() {
    document.body.style.overflow = 'hidden';
  },
  closeModal() {
    document.body.style.overflow = 'auto';
    this.modalOpen = false;
    this.expandFullscreen = false;
    expandFullScreen(this.expandFullscreen, 'modalGalleryFullScreen');
    backState();
  },
  expandPhoto(isExpand: boolean, id = "modalGalleryFullScreen") {
    this.expandFullscreen = isExpand;
    expandFullScreen(isExpand, id);
  }
});

const eventListenerUpdateSwiper = (name: string, isUpdateSlide = true) => {
  if (['photo', 'floor', 'video'].includes(name) && isUpdateSlide) {
    swipers.fullScreen[name]?.on('transitionEnd', function () {
      //console.log("transitionEnd")
      updateSlideByTab(name);
      /* for video tiktok swiper load swiper when slide transition */
      loadTiktokScript(name);
    });
  }

  document.addEventListener('keydown', function (e) {
    const tabSlideElement =
      document.getElementById('activeTabButton') as HTMLInputElement;
    let modal =
      document.getElementById('modal-fullscreen') as HTMLInputElement
    let tabSlide = tabSlideElement?.value ? tabSlideElement.value : 'photo';
    let isFullScreen = modal?.value === 'true';

    if (e.key === 'ArrowLeft') {
      if (tabSlide === name) {
        setTimeout(function () {
          if (name === "photo" && !isFullScreen) {
            swipers.main[name]?.slidePrev();
          } else {
            swipers.fullScreen[name]?.slidePrev(200);
          }
        }, 300);
      }
    } else if (e.key === 'ArrowRight') {
      if (tabSlide === name) {
        setTimeout(function () {
          if (name === "photo" && !isFullScreen) {
            swipers.main[name]?.slideNext();
          } else {
            swipers.fullScreen[name]?.slideNext(200);
          }
        }, 300);
      }
    }
  });
}

const updateSlideByTab = (name: string) => {
  let video = document.getElementById('number-video') as HTMLInputElement;
  let numberPhoto = swipers.fullScreen['photo'].slides.length;
  let numberVideo = video ? parseInt(video?.value) : 0;
  let currentPhoto: any;

  if (name === "photo") {
    currentPhoto = Math.abs(swipers.fullScreen['photo'].realIndex + numberVideo)
  } else if (name === "floor") {
    currentPhoto = swipers.fullScreen['floor'].realIndex + numberVideo + numberPhoto
  } else {
    currentPhoto = swipers.fullScreen[name].realIndex;
  }

  // update slide photo
  if (currentPhoto <= (numberPhoto - 1)) {
    swipers.main['photo']?.slideToLoop(currentPhoto);
    //console.log("updateSlideByTab",currentPhoto)
  }
}

const loadTiktokScript = (name: string) => {
  /* for video tiktok swiper load swiper when slide transition */
  if (name === "video") {
    let currentIndex = swipers.fullScreen[name].realIndex;
    let dataIdElm = swipers.fullScreen[name]?.slides[currentIndex]?.getAttribute('data-id')
    if (dataIdElm === "tiktok") {
      // load script tiktok
      loadTiktok();
    }
  }
}


const dispatchEventSlideGallery = (data: any) => {
  window.dispatchEvent(new CustomEvent('set-data-slide-gallery', {
    detail: data
  }));
}

const replaceState = (tabName: string) => {
  let currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('m', tabName);
  history.replaceState({modal: tabName}, "", currentUrl.toString());
}

const pushState = (tabName: string) => {
  let currentUrl = new URL(window.location.href);
  currentUrl.searchParams.set('m', tabName);
  history.pushState({modal: tabName}, "", currentUrl.toString());
}

const backState = () => {
  let currentUrl = new URL(window.location.href);
  currentUrl.searchParams.delete('m');
  history.replaceState({modal: false}, "", currentUrl.toString());
}

/**
 * Handle back button to close modal
 * */
window.onpopstate = function (event: any) {
  // call function alpine activeSlideByTab
  let tabSlide = event?.state?.modal;
  if (tabSlide === 'gridPhoto') {
    dispatchEventSlideGallery({tabSlide: 'gridPhoto'});
  } else {
    dispatchEventSlideGallery({modalOpen: false, expandFullscreen: false});
    expandFullScreen(false, 'modalGalleryFullScreen');
    document.body.style.overflow = 'auto';
  }
};
/**
 * Handle escape fullscreen docElmWithBrowsersFullScreenFn
 * */
document.addEventListener('fullscreenchange', function (e) {
  if (!document.fullscreenElement) {
    dispatchEventSlideGallery({expandFullscreen: false});
  }
});
/**
 * Handle escape button to close modal
 * */
document.addEventListener('keydown', function (e) {
  if (e.key === 'Escape') {
    // get current history state
    let tabSlide = history.state?.modal;
    if (tabSlide === 'photo') {
      dispatchEventSlideGallery({tabSlide: 'gridPhoto'});
      replaceState('gridPhoto');
    } else {
      dispatchEventSlideGallery({modalOpen: false, expandFullscreen: false});
      backState();
      expandFullScreen(false, 'modalGalleryFullScreen');
      document.body.style.overflow = 'auto';
    }
  }
});

export default {listingSlideGallery, dispatchEventSlideGallery};
